import React from 'react'

//STYLES
import '../../styles/index.scss'

const IHugInfo = ({ md }) => {
  return (
    <div id="ihuginfo">
      <div className="iHugInfoGrid">
        <div>
          <h2 className="how-iHug-work-title">{md.howDoesiHugWork}</h2>
          <p>{md.iHugWorkText}</p>
        </div>
        <video className="iHug-video" controls preload>
          <source
            src="http://ihug.se/wp-content/uploads/2015/08/iHug_med_vo_1.2.mp4"
            type="video/mp4"
          ></source>
        </video>
        {/* <iframe
          src="http://ihug.se/wp-content/uploads/2015/08/iHug_med_vo_1.2.mp4"
          className="iHug-video-call"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
        ></iframe> */}
      </div>
    </div>
  )
}

export default IHugInfo
