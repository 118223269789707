import React from 'react'

//STYLES
import '../../styles/index.scss'

const IHugInfo = ({ md }) => {
  return (
    <div id="ihuginfo">
      <div className="iHugInfoGridVideoCall video-call">
        <div className="div1">
          <iframe
            src="https://player.vimeo.com/video/518489686?title=0&amp;byline=0&amp;portrait=0"
            className="iHug-video-call"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
          ></iframe>
        </div>
        <div className="div2">
          <h2 className="how-iHug-work-title">{md.iHugVideoCall}</h2>
          <p>{md.iHugVideoText}</p>
        </div>
      </div>
    </div>
  )
}

export default IHugInfo
