import * as React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

// COMPONENTS
import Footer from '../components/footer'
import Landingpage from '../components/indexPageComponents/landingpage'
import IHugInfo from '../components/indexPageComponents/IHugInfo'
import IHugFunctions from '../components/indexPageComponents/IHugFunctions'
import IHugVideoCall from '../components/indexPageComponents/IHugVideoCall'

//STYLES
import '../styles/index.scss'

const IndexPage = ({ data }) => {
  let md = data.allMarkdownRemark.edges[0].node.frontmatter

  return (
    <div id="index">
      <Helmet>
        <title>Hem</title>
        <meta
          name="description"
          content="iHug – ökad trygghet för dig och dina anhöriga.
                  Förvandla en smartphone, surfplatta eller dator till ett trygghetslarm."
        ></meta>
      </Helmet>
      <Landingpage md={md} />
      <IHugInfo md={md} />
      <IHugVideoCall md={md} />
      <IHugFunctions md={md} />
      <Footer />
    </div>
  )
}

export const query = graphql`
  {
    allMarkdownRemark(filter: { frontmatter: { key: { eq: "index" } } }) {
      edges {
        node {
          frontmatter {
            catchphrase
            title
            iHugWorkText
            iHugVideoText
            howDoesiHugWork
            iHugVideoCall
            functionsArray {
              functionName
              functionText
              image {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
