import React from 'react'

// COMPONENTS
import Header from '../header'

//STYLES
import '../../styles/landingpage.scss'

//IMAGES
import wave from '../../images/wave.png'
import tablets from '../../images/tablets.png'

const Landingpage = ({ md }) => {
  return (
    <div id="landingpage">
      <div className="lp">
        <Header />
        <div className="landingpage-content">
          <div className="iHug-text">
            <h1 className="header-text">{md.title}</h1>
            <p className="under-text">{md.catchphrase}</p>
          </div>
          <div className="iHug-images">
            <img
              src={tablets}
              alt="iHug iPad and iPhone"
              className="tablets"
            ></img>
          </div>
        </div>

        <img src={wave} alt="wave" className="wave"></img>
      </div>
    </div>
  )
}

export default Landingpage
