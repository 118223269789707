import React from 'react'
import Img from 'gatsby-image'

//STYLES
import '../../styles/index.scss'

const IHugFunctions = ({ md }) => {
  return (
    <div id="ihugfunctions">
      <h2 className="function-title">iHugs funktioner</h2>
      <div className="iHug-functions-grid">
        {md.functionsArray.map((item, i) => (
          <div key={i} className="function-item">
            <div className="function-image">
              <Img fluid={item.image.childImageSharp.fluid}></Img>
            </div>
            <h3 className="function-h3">{item.functionName}</h3>
            <p>{item.functionText}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default IHugFunctions
